var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _vm.showLoading
        ? _c(
            "div",
            { staticClass: "loading-wrapper" },
            [
              _c("van-loading", { attrs: { type: "spinner" } }, [
                _vm._v("支付中..."),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("van-nav-bar", {
        attrs: {
          title: "确认信息",
          "left-text": "",
          "right-text": "",
          "left-arrow": "",
        },
        on: { "click-left": _vm.onClickLeft },
      }),
      _c("div", { staticClass: "content-box" }, [
        _c("div", { staticClass: "content-title" }, [_vm._v("商品内容")]),
        _c("div", { staticClass: "content-richText" }, [
          _vm._v(" " + _vm._s(_vm.goodsDetails.store_name) + " "),
        ]),
      ]),
      _c("van-field", {
        staticStyle: { "margin-top": "10px" },
        attrs: {
          type: "text",
          label: "姓名",
          placeholder:
            _vm.userInfoArr.length !== 0
              ? "请填写姓名或放大镜选择"
              : "请填写姓名",
          "right-icon": _vm.userInfoArr.length !== 0 ? "search" : "",
        },
        on: { "click-right-icon": _vm.showPopup },
        model: {
          value: _vm.userInfo.name,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "name", $$v)
          },
          expression: "userInfo.name",
        },
      }),
      _c("van-field", {
        attrs: { type: "text", label: "性别", placeholder: "请填写性别" },
        model: {
          value: _vm.userInfo.gender,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "gender", $$v)
          },
          expression: "userInfo.gender",
        },
      }),
      _c("van-field", {
        attrs: { type: "text", label: "年龄", placeholder: "请填写年龄" },
        model: {
          value: _vm.userInfo.age,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "age", $$v)
          },
          expression: "userInfo.age",
        },
      }),
      _c("van-field", {
        attrs: {
          type: "text",
          label: "近视度数(左)",
          placeholder: "请填写近视度数",
        },
        model: {
          value: _vm.userInfo.initialDegreeLeft,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "initialDegreeLeft", $$v)
          },
          expression: "userInfo.initialDegreeLeft",
        },
      }),
      _c("van-field", {
        attrs: {
          type: "text",
          label: "近视度数(右)",
          placeholder: "请填写近视度数",
        },
        model: {
          value: _vm.userInfo.initialDegreeRight,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "initialDegreeRight", $$v)
          },
          expression: "userInfo.initialDegreeRight",
        },
      }),
      _c("van-field", {
        attrs: {
          type: "text",
          label: "散光度数(左)",
          placeholder: "请填写散光度数",
        },
        model: {
          value: _vm.userInfo.astigmatismLeft,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "astigmatismLeft", $$v)
          },
          expression: "userInfo.astigmatismLeft",
        },
      }),
      _c("van-field", {
        attrs: {
          type: "text",
          label: "散光度数(右)",
          placeholder: "请填写散光度数",
        },
        model: {
          value: _vm.userInfo.astigmatismRight,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "astigmatismRight", $$v)
          },
          expression: "userInfo.astigmatismRight",
        },
      }),
      _c("van-field", {
        attrs: {
          type: "text",
          label: "散光方向(左)",
          placeholder: "请填写散光方向",
        },
        model: {
          value: _vm.userInfo.astigmatismDirectionLeft,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "astigmatismDirectionLeft", $$v)
          },
          expression: "userInfo.astigmatismDirectionLeft",
        },
      }),
      _c("van-field", {
        attrs: {
          type: "text",
          label: "散光方向(右)",
          placeholder: "请填写散光方向",
        },
        model: {
          value: _vm.userInfo.astigmatismDirectionRight,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "astigmatismDirectionRight", $$v)
          },
          expression: "userInfo.astigmatismDirectionRight",
        },
      }),
      _c("van-field", {
        attrs: { type: "text", label: "视力(左)", placeholder: "请填写视力" },
        model: {
          value: _vm.userInfo.visionLeft,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "visionLeft", $$v)
          },
          expression: "userInfo.visionLeft",
        },
      }),
      _c("van-field", {
        attrs: { type: "text", label: "视力(右)", placeholder: "请填写视力" },
        model: {
          value: _vm.userInfo.visionRight,
          callback: function ($$v) {
            _vm.$set(_vm.userInfo, "visionRight", $$v)
          },
          expression: "userInfo.visionRight",
        },
      }),
      _vm.reportImgArr.length !== 0
        ? _c(
            "div",
            { staticStyle: { "margin-left": "10px", "margin-top": "10px" } },
            [_vm._v(" 选择验光报告 ")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        _vm._l(_vm.reportImgArr, function (imgItem, imgIndex) {
          return _c(
            "div",
            {
              key: imgIndex,
              staticClass: "van-image",
              staticStyle: {
                width: "100px",
                height: "100px",
                "margin-left": "10px",
              },
            },
            [
              _c("img", {
                staticClass: "van-image__img",
                attrs: { src: imgItem.src },
                on: {
                  click: function ($event) {
                    return _vm.showImg(_vm.reportImgArr, imgIndex)
                  },
                },
              }),
              _c("i", {
                class:
                  "van-icon van-icon-success report_checkbox " +
                  (imgItem.selected ? "report_checkbox_selected" : ""),
                on: {
                  click: function ($event) {
                    return _vm.selectImg(imgItem, imgIndex)
                  },
                },
              }),
            ]
          )
        }),
        0
      ),
      _c("van-field", {
        staticStyle: { "margin-top": "10px" },
        attrs: {
          type: "text",
          label: "买家留言",
          placeholder: "选填，请先和商家协商一致",
        },
        model: {
          value: _vm.mark,
          callback: function ($$v) {
            _vm.mark = $$v
          },
          expression: "mark",
        },
      }),
      _c("van-submit-bar", {
        attrs: {
          loading: _vm.submitLoading,
          price: Number(_vm.goodsDetails.price) * 100,
          "button-text": "去付款",
          "button-color": "#1989fa",
        },
        on: { submit: _vm.onSubmit },
      }),
      _c(
        "van-dialog",
        {
          attrs: {
            title: "",
            "show-cancel-button": false,
            "show-confirm-button": false,
            "close-on-click-overlay": "",
          },
          model: {
            value: _vm.payDialogVisible,
            callback: function ($$v) {
              _vm.payDialogVisible = $$v
            },
            expression: "payDialogVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pay-container" },
            [
              _c("van-icon", {
                staticClass: "pay-dialong-icon-close",
                attrs: { name: "cross" },
                on: {
                  click: function ($event) {
                    _vm.payDialogVisible = false
                  },
                },
              }),
              _c("div", { staticClass: "pay-dialog-price" }, [
                _vm._v("￥" + _vm._s(Number(_vm.goodsDetails.price))),
              ]),
              _c(
                "van-radio-group",
                {
                  model: {
                    value: _vm.payRadio,
                    callback: function ($$v) {
                      _vm.payRadio = $$v
                    },
                    expression: "payRadio",
                  },
                },
                [
                  _c("van-cell", {
                    attrs: { clickable: "" },
                    on: {
                      click: function ($event) {
                        _vm.payRadio = 1
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("i", {
                              staticClass: "iconfont icon-adl-weixin",
                            }),
                            _c("div", [_vm._v("微信支付")]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "right-icon",
                        fn: function () {
                          return [_c("van-radio", { attrs: { name: 1 } })]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pay-dialog-button-box" },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "pay-dialog-button",
                      attrs: { type: "info", round: "" },
                      on: { click: _vm.jumpPayment },
                    },
                    [_vm._v("去支付")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.isShowPopup,
            callback: function ($$v) {
              _vm.isShowPopup = $$v
            },
            expression: "isShowPopup",
          },
        },
        [
          _c(
            "van-radio-group",
            {
              model: {
                value: _vm.userRadio,
                callback: function ($$v) {
                  _vm.userRadio = $$v
                },
                expression: "userRadio",
              },
            },
            [
              _c(
                "van-cell-group",
                _vm._l(_vm.userInfoArr, function (item) {
                  return _c("van-cell", {
                    key: item.name,
                    attrs: { title: item.name, clickable: "" },
                    on: {
                      click: function ($event) {
                        _vm.userRadio = item.name
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "right-icon",
                          fn: function () {
                            return [
                              _c("van-radio", { attrs: { name: item.name } }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "van-button",
            {
              staticClass: "user-popup__button",
              attrs: { type: "info", block: "" },
              on: { click: _vm.selectdUserInfo },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }